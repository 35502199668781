"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  (function initModals() {
    var modals = document.querySelectorAll("[data-modal]");
    var currentPlayer;
    function openModal(target) {
      var component = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      if (target.closest("[data-content]")) {
        var controller = target.closest("[data-content]");
        var currentModal = _toConsumableArray(modals).find(function (modal) {
          return modal.dataset.modal === controller.dataset.content;
        });
        var container = controller.closest(".spotlight-video-container").parentElement;
        var modalBack = container.querySelector(".modal-backdrop");
        var modalContainer = container.querySelector(".modal");
        currentModal.classList.add("active");
        modalContainer.classList.add("active");
        modalBack.classList.add("active");
        document.body.classList.add("scroll-disabled");
        if (component === "video") {
          var title = controller.getAttribute("title");
          var modalTitle = currentModal.querySelector(".modal-title");
          if (title && modalTitle && !modalTitle.textContent) {
            modalTitle.textContent = title;
          }
          var description = controller.getAttribute("description");
          var modalDescription = currentModal.querySelector(".modal-description");
          if (description && modalDescription) {
            modalDescription.textContent = description;
          }
          var assetpath = controller.getAttribute("assetpath");
          var videoPath = assetpath;
          if (videoPath != null && !videoPath.includes('.mp4')) {
            videoPath = videoPath + ".mp4";
          }
          var subtitles = controller.dataset.track;
          currentPlayer = jwplayer("".concat(currentModal.dataset.modal)).setup({
            file: videoPath,
            image: assetpath ? assetpath + "-preview.jpg" : null,
            width: "100%",
            aspectratio: "16:10",
            autostart: true,
            ga: {
              label: "title"
            },
            tracks: subtitles ? [{
              file: subtitles,
              label: "English",
              kind: "captions",
              default: true
            }] : null
          });
          currentPlayer.play();
        }
      }
    }
    function closeModal(target) {
      var modalContainer = arguments[0].closest(".modal");
      var modalBack = modalContainer.parentElement.querySelector(".modal-backdrop");
      var clickedOnClose = target.closest(".close") != null;
      var clickedOnModal = target.closest(".modal-content") != null;
      if (clickedOnModal && !clickedOnClose) {
        return;
      }
      var isModalActive = modalContainer.classList.contains('active');
      if (!isModalActive) {
        return;
      }
      if (modals) {
        modals.forEach(function (modal) {
          modal.classList.remove("active");
        });
      }
      if (currentPlayer) {
        currentPlayer.stop();
      }
      if (modals) {
        modals.forEach(function (modal) {
          modal.classList.remove("active");
        });
      }
      modalContainer.classList.remove("active");
      modalBack.classList.remove("active");
      document.body.classList.remove("scroll-disabled");
    }
    function isModalActive() {
      var modal = document.querySelector(".modal-backdrop.active");
      return modal != null;
    }
    document.addEventListener("click", function (e) {
      var isActive = isModalActive();
      if (e.target.closest(".spotlight-video-container__link")) {
        isActive ? closeModal(e.target, "video") : openModal(e.target, "video");
      } else {
        isActive ? closeModal(e.target) : openModal(e.target);
      }
    });
  })();
});