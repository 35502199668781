"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function onHeroCarouselLoad() {
    var Selectors = {
      HERO_CAROUSEL: '.intertek-hero-carousel'
    };
    if (typeof Swiper === "undefined") {
      return;
    }
    var speed = 1500;
    var heroCarousels = document.querySelectorAll(Selectors.HERO_CAROUSEL);
    if (heroCarousels && heroCarousels.length > 0) {
      heroCarousels.forEach(function (elem, index) {
        var timing = elem.dataset.timing;
        if (timing && timing > 0) {
          speed = timing;
        }
        var heroCarousel = initSwiper(elem);
        if (timing && timing == 0) {
          heroCarousel.autoplay.stop();
        }
        addArrowClickListener(heroCarousel, elem);
        addBulletClickListener(heroCarousel, elem);
      });
    }
    function addBulletClickListener(heroCarousel, container) {
      var slides = container.querySelectorAll('.swiper-pagination-bullet');
      if (slides) {
        slides.forEach(function (slide) {
          slide.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var index = slide.dataset.index;
            heroCarousel.slideTo(index, 400, true);
          });
        });
      }
    }
    function addArrowClickListener(heroCarousel, container) {
      var leftArrow = container.querySelector('.swiper-navigation-arrow-left');
      if (leftArrow) {
        leftArrow.addEventListener('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          heroCarousel.slidePrev(400, true);
        });
      }
      var rightArrow = container.querySelector('.swiper-navigation-arrow-right');
      if (rightArrow) {
        rightArrow.addEventListener('click', function (e) {
          e.preventDefault();
          e.stopPropagation();
          heroCarousel.slideNext(400, true);
        });
      }
    }
    function initSwiper(container) {
      var options = {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function renderBullet(index, className) {
            var html = '<span data-index="' + (index + 1) + '" class="swiper-pagination-bullet-white swiper-pagination-bullet ' + className + '" tabindex="0" role="button" aria-label="Go to slide ' + (index + 1) + '"></span>';
            if (index == 0) {
              html = '<img class="swiper-navigation-arrow swiper-navigation-arrow-left" src="/images/icons/left-white.svg" >' + html;
            }
            var slides = container.querySelectorAll('.swiper-slide');
            if (slides) {
              var slidesCount = slides.length - 3; // in a loop swiper there are two additional slides
              if (slidesCount == index) {
                html = html + '<img class="swiper-navigation-arrow swiper-navigation-arrow-right" src="/images/icons/right-white.svg" >';
              }
            }
            return html;
          }
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          992: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0
          },
          1440: {
            slidesPerView: 1,
            spaceBetween: 0
          }
        },
        autoplay: {
          delay: speed
        }
      };
      return new Swiper(container, options);
    }
  })();
});