"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  (function onDesktopNavigationHover() {
    var navigation = document.querySelector(".intertek-header__navigation");
    var dropdownMenus = document.querySelectorAll(".intertek-flyout-menu");
    var removeActive = function removeActive(parent, selector) {
      var items = parent.querySelectorAll("".concat(selector));
      items.forEach(function (item) {
        return item.classList.remove("active");
      });
    };
    if (navigation) {
      navigation.addEventListener("mouseover", function (e) {
        var activeTab = e.target.closest(".intertek-flyout-menu__tab");
        if (activeTab) {
          if (dropdownMenus) {
            dropdownMenus.forEach(function (menu) {
              if (menu.contains(activeTab)) {
                removeActive(menu, ".intertek-flyout-menu__tab");
                removeActive(menu, ".intertek-flyout-menu__content");
              }
            });
          }
          activeTab.classList.add("active");
          var activeData = activeTab.dataset.tab;
          var activeContent = document.querySelector("[data-content=".concat(activeData, "]"));
          activeContent.classList.add("active");
        }
      });
    }
  })();
  (function onFormManipulate() {
    var mockedSuggestions = [{
      suggestion: "Food Packaging Testing",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "Food Packaging Testing and Analysis",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "Packaging Testing Services",
      url: "https://www.intertek.com/food-healthcare/"
    }, {
      suggestion: "Active and Intelligent Food Packaging Legislation",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "International Featured Standard Food Packaging Migration Testing",
      url: "https://www.intertek.com/food/"
    }];
    var formPlaceholder = document.querySelector(".intertek-search-form-placeholder");
    var formContainer = document.querySelector(".intertek-search-form-container");
    var formSuggestions = document.querySelector(".intertek-search-form-suggestions");
    var _baseUrl = document.body.dataset.searchUrl;
    if (formPlaceholder && formContainer) {
      var form = formContainer.querySelector(".intertek-search-form");
      formPlaceholder.addEventListener("click", function () {
        formPlaceholder.classList.add("active");
        formContainer.classList.add("active");
        var searchForm = document.getElementsByClassName("intertek-search-form");
        if (!searchForm) {
          return;
        }
        if (!searchForm[0]) {
          return;
        }
        var searchInput = searchForm[0][0];
        if (!searchInput) {
          return;
        }
        searchInput.focus();
      });
      var closeBtn = formPlaceholder.querySelector(".intertek-search-form-close");
      if (closeBtn) {
        closeBtn.addEventListener("click", function (e) {
          e.stopPropagation();
          formPlaceholder.classList.remove("active");
          formContainer.classList.remove("active");
        });
      }
      var formInput = formContainer.querySelector(".intertek-search-form input");
      var getHtmlTemplate = function getHtmlTemplate(item) {
        return "\n                    <a href=\"".concat(item.url, "\" \n                        class=\"intertek-search-form-suggestions__content-item xxs-text\">\n                        ").concat(item.suggestion, "\n                    </a>\n                ");
      };
      var builtSearchHref = function builtSearchHref(baseUrl, value) {
        return baseUrl ? "".concat(baseUrl, "/search/?q=").concat(value) : "/search/?q=".concat(value);
      };
      var searchLink = formSuggestions ? formSuggestions.querySelector(".intertek-search-form-suggestions__link") : null;
      var onFormSearch = function onFormSearch(e) {
        // fetch emulation
        var searchValue = e.target.value;
        var SearchSuggestionsList = [];
        if (formSuggestions) {
          fetch("/Search/QuickSearch?q=" + searchValue).then(function (response) {
            return response.json();
          }).then(function (json) {
            SearchSuggestionsList = JSON.parse(json);
            //console.log(SearchSuggestionsList);

            ///Move code in between here
            var filteredSuggestions = SearchSuggestionsList.filter(function (item) {
              var result = item.suggestion.toLowerCase().includes(searchValue.toLowerCase());
              return result;
            });
            var formSuggestionsContent = formSuggestions.querySelector(".search-block-form-suggestions-content");
            var clearSuggestions = function clearSuggestions() {
              return formSuggestionsContent ? formSuggestionsContent.remove() : null;
            };
            if (!searchValue.length) {
              formInput.classList.remove("showing-search-results");
              formSuggestions.classList.remove("active");
              formSuggestions.classList.remove("no-results");
              clearSuggestions();
            }
            if (searchLink) {
              searchLink.setAttribute("href", builtSearchHref(_baseUrl, searchValue));
            }
            if (searchValue.length >= 3) {
              formInput.classList.add("showing-search-results");
              if (filteredSuggestions.length) {
                formSuggestions.classList.add("active");
                formSuggestions.classList.remove("no-results");
              } else {
                formSuggestions.classList.remove("active");
                formSuggestions.classList.add("no-results");
              }
              var builtHtmlString = filteredSuggestions.map(function (item) {
                return getHtmlTemplate(item);
              }).join("");
              clearSuggestions();
              formSuggestions.insertAdjacentHTML("afterbegin", "<div class=\"search-block-form-suggestions-content\">".concat(builtHtmlString, "</div>"));
            }
            ///Code move end
          }).catch(function (error) {
            return console.error(error);
          }).finally(function () {
            //console.log("Data received 1 --> ", json);
            //alert(data);
            //alert(SearchSuggestionsList);
            //json = null;
          });
        }
      };
      if (formInput) {
        formInput.addEventListener("input", onFormSearch);
      }
      if (form) {
        form.addEventListener("submit", function (e) {
          e.preventDefault();
          if (formInput) {
            window.open(builtSearchHref(_baseUrl, formInput.value), "_self");
          }
        });
      }
    }
  })();
  (function mobileNavigation() {
    var headerWrapper = document.querySelector(".intertek-header-wrapper");
    if (headerWrapper) {
      var mobileHeader = headerWrapper.querySelector(".intertek-mobile-menu");
      var formContainer = headerWrapper.querySelector(".intertek-search-form-container");
      var dropdownTogglers = document.querySelectorAll(".intertek-mobile-menu-dropdown-toggler");
      var mainPageContent = document.querySelector("main");
      if (mobileHeader && formContainer) {
        headerWrapper.addEventListener("click", function (e) {
          if (e.target.closest(".intertek-mobile-search-btn .intertek-mobile-search-icon")) {
            mobileHeader.classList.add("active");
            mobileHeader.classList.add("search-opened");
            formContainer.classList.add("active");
            if (mainPageContent) {
              mainPageContent.classList.add("fixed");
            }
          }
          if (e.target.closest(".intertek-mobile-burger .intertek-mobile-burger-icon")) {
            mobileHeader.classList.add("active");
            mobileHeader.classList.remove("search-opened");
            formContainer.classList.remove("active");
            if (mainPageContent) {
              mainPageContent.classList.add("fixed");
            }
          }
          if (e.target.closest(".intertek-mobile-burger .intertek-mobile-close-icon") || e.target.closest(".intertek-mobile-search-btn .intertek-mobile-close-icon")) {
            mobileHeader.classList.remove("active");
            mobileHeader.classList.remove("search-opened");
            formContainer.classList.remove("active");
            if (mainPageContent) {
              mainPageContent.classList.remove("fixed");
            }
            if (dropdownTogglers) {
              dropdownTogglers.forEach(function (toggler) {
                toggler.classList.remove("active");
                if (toggler.nextElementSibling) {
                  toggler.nextElementSibling.style.maxHeight = "0";
                }
              });
            }
          }
        });
      }
    }
  })();
  (function expandCollapseMobileNav() {
    var mobileNav = document.querySelector(".intertek-mobile-menu");
    var childHeightBeforeCollapse = 0;
    if (mobileNav) {
      mobileNav.addEventListener("click", function (e) {
        if (e.target.closest(".intertek-mobile-menu-dropdown-toggler")) {
          e.preventDefault();
          var activeLink = e.target.closest(".intertek-mobile-menu-dropdown-toggler");
          if (activeLink) {
            activeLink.classList.toggle("active");
            var dropdown = activeLink.nextElementSibling ? activeLink.nextElementSibling : null;
            if (dropdown && dropdown.children) {
              var parentDropdown = dropdown.parentNode.parentNode;
              var setParentHeight = function setParentHeight(node, child) {
                if (node && node.classList.contains("intertek-mobile-menu-dropdown")) {
                  if (child.previousElementSibling.classList.contains("active")) {
                    childHeightBeforeCollapse = parseInt(child.style.maxHeight);
                    node.style.maxHeight = "".concat(parseInt(node.style.maxHeight) + parseInt(child.style.maxHeight), "px");
                  } else {
                    node.style.maxHeight = "".concat(parseInt(node.style.maxHeight) - childHeightBeforeCollapse, "px");
                  }
                }
              };
              if (activeLink.classList.contains("active")) {
                var dropdownHeight = _toConsumableArray(dropdown.children).reduce(function (accum, item) {
                  return accum += item.clientHeight;
                }, 0);
                dropdown.style.maxHeight = "".concat(dropdownHeight ? dropdownHeight : 0, "px");
              } else {
                dropdown.style.maxHeight = 0;
              }
              setParentHeight(parentDropdown, dropdown);
            }
          }
        }
      });
    }
  })();
});