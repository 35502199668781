"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  var Selectors = {
    SPOTLIGHT_FILTER_SWIPER: ".intertek-filter-slider"
  };
  if (typeof Swiper === "undefined") {
    return;
  }
  var spotlightSwipers = document.querySelectorAll(Selectors.SPOTLIGHT_FILTER_SWIPER);
  var swiper = {};
  var initSwiper = function initSwiper() {
    swiper = new Swiper(".intertek-filter-slider", {
      navigation: {
        nextEl: ".swiper-navigation-arrow.swiper-navigation-arrow-right",
        prevEl: ".swiper-navigation-arrow.swiper-navigation-arrow-left"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function renderBullet(index, className) {
          return '<span data-index="' + index + '" class="swiper-pagination-bullet ' + className + '" tabindex="0" role="button" aria-label="Go to slide ' + (index + 1) + '"></span>';
        }
      },
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 10
        },
        480: {
          slidesPerView: 2.1,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 1.2,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 1.8,
          spaceBetween: 16
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 15
        },
        1440: {
          slidesPerView: 4.7,
          spaceBetween: 30
        }
      }
    });
  };
  if (spotlightSwipers && spotlightSwipers.length > 0) {
    initSwiper();
  }
  var filterSlides = function filterSlides() {
    var filters = document.querySelector(".intertek-container-filters-list");
    var filterLinks = document.querySelectorAll(".intertek-container-filters-link");
    var slides = document.querySelectorAll(".intertek-filter-slider .intertek-filter-slider-slide");
    var manipulateSlides = function manipulateSlides(handler, slides, flag) {
      if (flag) {
        return _toConsumableArray(slides).filter(function (item) {
          return item.dataset.type === handler.dataset.link;
        });
      }
      return _toConsumableArray(slides).filter(function (item) {
        return item.dataset.type !== handler.dataset.link;
      });
    };
    var setSlidesVisibility = function setSlidesVisibility(slides, flag) {
      if (flag) {
        return _toConsumableArray(slides).forEach(function (item) {
          item.classList.remove("hidden");
        });
      }
      return _toConsumableArray(slides).forEach(function (item) {
        item.classList.add("hidden");
      });
    };
    var resetFilter = function resetFilter(filterLinks, currentLink) {
      _toConsumableArray(filterLinks).forEach(function (link) {
        if (!link.isSameNode(currentLink)) {
          link.dataset.active = "false";
        }
      });
    };
    var handleFilterClick = function handleFilterClick(e) {
      var currentLink = e.target.closest(".intertek-container-filters-link");
      if (currentLink) {
        e.preventDefault();
        if (currentLink.dataset.link) {
          resetFilter(filterLinks, currentLink);
          var hiddenSlides = currentLink.dataset.active === "true" ? [] : manipulateSlides(currentLink, slides, false);
          var visibleSlides = currentLink.dataset.active === "true" ? slides : manipulateSlides(currentLink, slides, true);
          setSlidesVisibility(visibleSlides, true);
          setSlidesVisibility(hiddenSlides, false);
          swiper.update();
          currentLink.dataset.active = currentLink.dataset.active === "true" ? "false" : "true";
        }
      }
    };
    filters && filters.addEventListener("click", handleFilterClick);
  };
  filterSlides();
});