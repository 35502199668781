"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function onContentListFilterChange() {
    var form = document.querySelector('.content-list-filter-block__form');
    var formSelect = form && form.querySelectorAll('select');
    var resultTableBody = document.querySelector('.content-list-filter-block__results-container table');
    var resetButton = document.querySelector('.content-list-filter-block__action-item.reset-button');
    var mockedData = [{
      title: "IEC 60335-1 Ed. 6 Annex B: Battery Operated Household Appliances",
      url: "https://www.google.com/",
      industry: 1,
      region: 1,
      year: 2023,
      event: 2,
      date: '20 Mar 2023'
    }, {
      title: "NFPA 285 - 2023 Edition: An Overview of the Changes",
      url: "https://www.google.com/",
      industry: 1,
      region: 2,
      year: 2023,
      event: 2,
      date: '21 Mar 2023'
    }, {
      title: "Windows, Doors, and Skylights in North America: NAFS-22",
      url: "https://www.google.com/",
      industry: 2,
      region: 3,
      year: 2022,
      event: 1,
      date: '22 Mar 2023'
    }, {
      title: "Preparing Suppliers for Success: ZDHC MRSL Version 3.0, Wastewater Testing and Training Academy",
      url: "https://www.google.com/",
      industry: 4,
      region: 1,
      year: 2022,
      event: 1,
      date: '23 Mar 2023'
    }, {
      title: "Turkey Updates on KKDIK Registration",
      url: "https://www.google.com/",
      industry: 3,
      region: 5,
      year: 2022,
      event: 3,
      date: '24 Mar 2023'
    }];

    // This flag controls whether there will be a date column in the result set.
    var showDates = window.location.href.split('/').at(-1) === 'content-list-filter-block-with-4-filter.html'; // For now, we are showing the date column in 4-filter view.

    var getHtmlTemplate = function getHtmlTemplate(item) {
      if (showDates) {
        return "\n                    <tr>\n                        <td>\n                            ".concat(item.date, "\n                        </td>\n                        <td>\n                            <a href=\"").concat(item.url, "\" class=\"content-list-filter-block__action-item\">\n                                ").concat(item.title, "\n                            </a>\n                        </td>\n                    </tr>\n                ");
      } else {
        return "\n                <tr>\n                    <td>\n                        <a href=\"".concat(item.url, "\" class=\"content-list-filter-block__action-item\">\n                            ").concat(item.title, "\n                        </a>\n                    </td>\n                </tr>\n            ");
      }
    };
    var getResult = function getResult() {
      var data = mockedData;
      for (var i = 0; i < formSelect.length; i++) {
        var name = formSelect[i].name;
        var value = formSelect[i].value;
        if (name && value > 0) {
          data = data.filter(function (item) {
            return item[name] == value;
          });
        }
      }
      return data;
    };
    var setResult = function setResult(data) {
      var result = data.map(function (item) {
        return getHtmlTemplate(item);
      }).join("");
      resultTableBody && resultTableBody.insertAdjacentHTML("afterbegin", "<tbody class=\"content-list-filter-block__results\">".concat(result, "</tbody>"));
    };
    var clearResult = function clearResult() {
      resultTableBody && resultTableBody.querySelector('.content-list-filter-block__results').remove();
    };
    var clearFilter = function clearFilter() {
      for (var i = 0; i < formSelect.length; i++) {
        formSelect[i].selectedIndex = 0;
      }
      setResult(getResult());
    };
    setResult(mockedData);
    if (form && formSelect.length > 0) {
      for (var i = 0; i < formSelect.length; i++) {
        formSelect[i].addEventListener('change', function (e) {
          clearResult();
          setResult(getResult());
        });
      }
    }
    if (resetButton) {
      resetButton.addEventListener('click', function (e) {
        e.preventDefault();
        clearResult();
        clearFilter();
      });
    }
  })();
});