"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var Selectors = {
    SPOTLIGHT_SWIPER: ".intertek-spotlight-home-slider"
  };
  if (typeof Swiper === "undefined") {
    return;
  }
  var spotlightSwipers = document.querySelectorAll(Selectors.SPOTLIGHT_SWIPER);
  var initSwiper = function initSwiper() {
    var swiper = new Swiper(".intertek-spotlight-home-slider", {
      navigation: {
        nextEl: ".swiper-navigation-arrow.swiper-navigation-arrow-right",
        prevEl: ".swiper-navigation-arrow.swiper-navigation-arrow-left"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function renderBullet(index, className) {
          return '<span data-index="' + index + '" class="swiper-pagination-bullet ' + className + '" tabindex="0" role="button" aria-label="Go to slide ' + (index + 1) + '"></span>';
        }
      },
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 10
        },
        640: {
          slidesPerView: 1.2,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 1.29,
          spaceBetween: 15
        },
        992: {
          slidesPerView: 1.77,
          spaceBetween: 15
        },
        1440: {
          slidesPerView: 2.35,
          spaceBetween: 30
        }
      }
    });
  };
  if (spotlightSwipers && spotlightSwipers.length > 0) {
    initSwiper();
  }
});