"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function onSubnavigationContainerClick() {
    var activeClass = "active";
    var accordionSelector = "intertek-accordion-cntr__item__title";
    var accordion = document.getElementsByClassName(accordionSelector);
    if (!accordion) {
      return;
    }
    for (var i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener("click", function () {
        this.classList.toggle(activeClass);
        this.nextElementSibling.classList.toggle(activeClass);
        toggleIcon(this);
        setPanelHeight(this);
      });
    }
    function toggleIcon(accordionItem) {
      var a = accordionItem.children[0];
      if (!a) {
        return;
      }
      var span = a.children[0];
      if (!span) {
        return;
      }
      var img = span.children[0];
      if (!img) {
        return;
      }
      var plusIcon = "/images/icons/mega-menu-gray-plus.png";
      var minusIcon = "/images/icons/mega-menu-gray-minus.png";
      if (accordionItem.classList.contains(activeClass)) {
        img.src = minusIcon;
      } else {
        img.src = plusIcon;
      }
    }
  })();
});