"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  var _document$querySelect;
  var Selectors = {
    SOCIAL_MEDIA_BLOCK: ".jsSocialMedia",
    SOCIAL_MEDIA_ITEM: ".jsSocialMediaItem",
    SOCIAL_MEDIA_LINK: ".jsSocialMediaLink",
    TOGGLE_EL: ".jsToggleEl"
  };
  var ClassNames = {
    ACTIVE: "active"
  };
  var socialMediaBlocks = (_document$querySelect = document.querySelectorAll(Selectors.SOCIAL_MEDIA_BLOCK)) !== null && _document$querySelect !== void 0 ? _document$querySelect : [];
  _toConsumableArray(socialMediaBlocks).forEach(initSocialMediaBlock);
  function initSocialMediaBlock(socialMediaBlock) {
    var _socialMediaBlock$que;
    var socialMediaItems = (_socialMediaBlock$que = socialMediaBlock.querySelectorAll(Selectors.SOCIAL_MEDIA_ITEM)) !== null && _socialMediaBlock$que !== void 0 ? _socialMediaBlock$que : [];
    Array.from(socialMediaItems).forEach(function (item) {
      return initSocialMediaIcon(item, socialMediaItems, socialMediaBlock);
    });
  }
  function updateLinksArea(item, socialMediaItems, toggleEl, block, state, type) {
    if (!block.classList.contains(ClassNames.ACTIVE) && state !== type) {
      socialMediaItems.forEach(function (item) {
        return item.classList.remove(ClassNames.ACTIVE);
      });
      block.classList.toggle(ClassNames.ACTIVE);
      toggleEl.classList.toggle(ClassNames.ACTIVE);
      item.classList.toggle(ClassNames.ACTIVE);
      block.setAttribute("data-state", type);
      toggleEl.style.opacity = toggleEl.style.opacity === 1 ? 0 : 1;
    }
  }
  function initSocialMediaIcon(item, socialMediaItems, block) {
    var icon = item.querySelector(Selectors.SOCIAL_MEDIA_LINK);
    var toggleEl = item.querySelector(Selectors.TOGGLE_EL);
    icon.addEventListener("click", function () {
      var state = block.getAttribute("data-state");
      var type = icon.getAttribute("data-type");
      var isActive = block.classList.contains(ClassNames.ACTIVE);
      if (isActive) {
        block.classList.toggle(ClassNames.ACTIVE);
        item.classList.toggle(ClassNames.ACTIVE);
        toggleEl.classList.toggle(ClassNames.ACTIVE);
        toggleEl.style.opacity = 0;
        block.setAttribute("data-state", "");
        if (state !== type) {
          socialMediaItems.forEach(function (item) {
            return item.classList.remove(ClassNames.ACTIVE);
          });
          item.classList.toggle(ClassNames.ACTIVE);
        }
      }
      setTimeout(function () {
        return updateLinksArea(item, socialMediaItems, toggleEl, block, state, type);
      }, isActive ? 200 : 0);
    });
  }
});