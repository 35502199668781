"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function heroVideoSetTimeout() {
    var video = document.querySelector(".intertek-hero-block-container__banner");
    if (video && video.dataset.poster && video.dataset.timeout > 0) {
      setTimeout(function () {
        if (video.readyState < 3) {
          video.autoplay = false;
          video.poster = video.dataset.poster;
        }
      }, video.dataset.timeout);
    }
  })();
});