"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var _document$querySelect;
  var Selectors = {
    PHONES_LIST_BOX: ".jsPhonesListBox",
    PHONES_LIST_HEADER: ".jsPhonesListHeader",
    PHONES_LIST_TOGGLE: ".jsPhonesListToggle",
    TOGGLE_ICON: ".jsPhonesListToggleIcon",
    TOGGLE_ICON_ACTIVE: ".jsPhonesListToggleActiveIcon",
    PHONE_CONTACT: ".jsPhoneContact"
  };
  var ClassNames = {
    ACTIVE: "active"
  };
  var phoneslistBoxes = (_document$querySelect = document.querySelectorAll(Selectors.PHONES_LIST_BOX)) !== null && _document$querySelect !== void 0 ? _document$querySelect : [];
  Array.from(phoneslistBoxes).forEach(initPhonesBox);
  function togglePhonesList(toggleEl, toggleIcon, toggleIconActive) {
    toggleEl.classList.toggle(ClassNames.ACTIVE);
    toggleIcon.classList.toggle(ClassNames.ACTIVE);
    toggleIconActive.classList.toggle(ClassNames.ACTIVE);
    if (toggleEl.style.maxHeight) {
      toggleEl.style.maxHeight = null;
    } else {
      toggleEl.style.maxHeight = toggleEl.scrollHeight + "px";
    }
  }
  function initPhonesBox(phonesBox) {
    var headerEl = phonesBox.querySelector(Selectors.PHONES_LIST_HEADER);
    var toggleEl = phonesBox.querySelector(Selectors.PHONES_LIST_TOGGLE);
    var toggleIcon = phonesBox.querySelector(Selectors.TOGGLE_ICON);
    var toggleIconActive = phonesBox.querySelector(Selectors.TOGGLE_ICON_ACTIVE);
    var phones = phonesBox.querySelectorAll(Selectors.PHONE_CONTACT);
    if (headerEl && toggleEl) {
      if (phones && phones.length <= 2) {
        togglePhonesList(toggleEl, toggleIcon, toggleIconActive);
      }
      headerEl.addEventListener("click", function () {
        togglePhonesList(toggleEl, toggleIcon, toggleIconActive);
      });
    }
  }
});