"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var initRteModals = function initRteModals() {
    var modalLinks = document.querySelectorAll('[data-toggle="modal"]');
    var modals = document.querySelectorAll(".RichTextBlock .modal");
    var openModalByClick = function openModalByClick(e) {
      if (e.target.closest("[data-target]") && e.target.closest("[data-target]").dataset.target) {
        e.preventDefault();
        var target = e.target.closest("[data-target]").dataset.target;
        var currentModal = document.querySelector(target);
        setTimeout(function () {
          currentModal.classList.add("in");
        }, 150);
        currentModal.style.display = "block";
        document.body.classList.add("scroll-disabled");
      }
    };
    var closeAllModals = function closeAllModals() {
      modals.length && modals.forEach(function (modal) {
        if (modal.classList.contains("in")) {
          setTimeout(function () {
            modal.style.display = "none";
          }, 150);
          modal.classList.remove("in");
        }
      });
      document.body.classList.remove("scroll-disabled");
    };
    var closeModal = function closeModal(e) {
      if (e.target.closest(".close") || e.target.closest(".modal") && !e.target.closest(".modal-dialog")) {
        closeAllModals();
      }
      if (e.keyCode == 27) {
        closeAllModals();
      }
    };
    document.addEventListener("click", openModalByClick);
    document.addEventListener("click", closeModal);
    document.addEventListener("keydown", closeModal);
  };
  initRteModals();
});