"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function onSearchBlockFormManipulate() {
    var mockedSuggestions = [{
      suggestion: "Food Packaging Testing",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "Food Packaging Testing and Analysis",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "Packaging Testing Services",
      url: "https://www.intertek.com/food-healthcare/"
    }, {
      suggestion: "Active and Intelligent Food Packaging Legislation",
      url: "https://www.intertek.com/food/"
    }, {
      suggestion: "International Featured Standard Food Packaging Migration Testing",
      url: "https://www.intertek.com/food/"
    }];
    var form = document.querySelector(".search-block__search-container__form");
    var formInput = document.querySelector(".search-block__search-container__form input");
    var formSuggestions = document.querySelector(".search-block__search-container__form-suggestions");
    var searchLink = formSuggestions ? formSuggestions.querySelector(".search-block__search-container__form-suggestions .search-block-form-suggestions-link") : null;
    var _baseUrl = document.body.dataset.searchUrl;
    var builtSearchHref = function builtSearchHref(baseUrl, value) {
      return baseUrl ? "".concat(baseUrl, "/search/?q=").concat(value) : "/search/?q=".concat(value);
    };
    var getHtmlTemplate = function getHtmlTemplate(item) {
      return "\n                <a href=\"".concat(item.url, "\" \n                    class=\"search-block-form-suggestions-link-content-item\">\n                    ").concat(item.suggestion, "\n                </a>\n            ");
    };
    var onFormSearch = function onFormSearch(e) {
      var searchValue = e.target.value;
      if (formSuggestions) {
        fetch("/Search/QuickSearch?q=" + searchValue).then(function (response) {
          return response.json();
        }).then(function (json) {
          SearchSuggestionsList = JSON.parse(json);
          //console.log(SearchSuggestionsList);

          ///Move code in between here
          var filteredSuggestions = SearchSuggestionsList.filter(function (item) {
            var result = item.suggestion.toLowerCase().includes(searchValue.toLowerCase());
            return result;
          });
          var formSuggestionsContent = formSuggestions.querySelector(".search-block-form-suggestions-content");
          var clearSuggestions = function clearSuggestions() {
            return formSuggestionsContent ? formSuggestionsContent.remove() : null;
          };
          if (!searchValue.length) {
            formInput.classList.remove("showing-search-results");
            formSuggestions.classList.remove("active");
            formSuggestions.classList.remove("no-results");
            clearSuggestions();
          }
          if (searchLink) {
            searchLink.setAttribute("href", builtSearchHref(_baseUrl, searchValue));
          }
          if (searchValue.length >= 3) {
            formInput.classList.add("showing-search-results");
            if (filteredSuggestions.length) {
              formSuggestions.classList.add("active");
              formSuggestions.classList.remove("no-results");
            } else {
              formSuggestions.classList.remove("active");
              formSuggestions.classList.add("no-results");
            }
            var builtHtmlString = filteredSuggestions.map(function (item) {
              return getHtmlTemplate(item);
            }).join("");
            clearSuggestions();
            formSuggestions.insertAdjacentHTML("afterbegin", "<div class=\"search-block-form-suggestions-content\">".concat(builtHtmlString, "</div>"));
          }
          ///Code move end
        }).catch(function (error) {
          return console.error(error);
        }).finally(function () {
          //console.log("Data received 1 --> ", json);
          //alert(data);
          //alert(SearchSuggestionsList);
          //json = null;
        });
      }
    };
    if (formInput) {
      formInput.addEventListener("input", onFormSearch);
      formInput.addEventListener("focus", onFormSearch);
    }
    if (form) {
      form.addEventListener("submit", function (e) {
        e.preventDefault();
        if (formInput) {
          window.open(builtSearchHref(_baseUrl, formInput.value), "_self");
        }
      });
    }
    document.addEventListener("click", function (e) {
      if (!e.target.closest(".search-block__search-container")) {
        if (formSuggestions) {
          formSuggestions.classList.remove("active");
          formSuggestions.classList.remove("no-results");
        }
        if (formInput) {
          formInput.classList.remove("showing-search-results");
        }
      }
    });
  })();
});