"use strict";

document.addEventListener("DOMContentLoaded", function () {
  // DO NOT REMOVE. It's needed to add placeloholders from placeholder.com
  // (function addPlaceholdersSizes() {
  //     const pictures = document.querySelectorAll("picture");
  //     pictures.forEach((picture) => {
  //         const sources = picture.querySelectorAll("source");
  //         if (sources) {
  //             sources.forEach((source) => {
  //                 const srcSetArr = source.srcset.split(",");
  //                 const firstImgSizes = (number) => {
  //                     return srcSetArr[0]
  //                         .slice(69, srcSetArr[0].length - 2)
  //                         .split("x")
  //                         .map((item) => {
  //                             return item * number;
  //                         })
  //                         .join("x");
  //                 };
  //                 source.srcset = `${srcSetArr[0].slice()}, ${srcSetArr[0].slice(0, 68)}/${firstImgSizes(2)} 2x, ${srcSetArr[0].slice(
  //                     0,
  //                     68
  //                 )} ${firstImgSizes(3)} 3x`;
  //             });
  //         }
  //     });
  // })();
});