"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  (function redirectByClick() {
    var select = document.querySelector(".location-page-select");
    if (select) {
      select.addEventListener("change", function () {
        window.location.href = this.options[this.selectedIndex].value;
      });
    }
  })();
  (function locationLinkOver() {
    var map = document.querySelector(".locations-page-map");
    if (map) {
      var mapSectors = map.querySelectorAll(".locations-page-map-icon");
      var mapSector;
      document.addEventListener("mouseover", function (e) {
        if (e.target.closest(".locations-page-link")) {
          mapSectors.forEach(function (item) {
            return item.classList.remove("active");
          });
          if (e.target.hasAttribute("href")) {
            var href = e.target.getAttribute("href");
            mapSector = _toConsumableArray(mapSectors).find(function (item) {
              return item.dataset.link === href;
            });
            if (mapSector) {
              mapSector.classList.add("active");
            }
          }
        } else {
          if (mapSector) {
            mapSector.classList.remove("active");
          }
        }
      });
    }
  })();
  (function mapSectorClick() {
    document.addEventListener("click", function (e) {
      if (e.target.closest(".locations-page-map-icon")) {
        var currentSector = e.target.closest(".locations-page-map-icon");
        var currentAttr = currentSector.dataset.link;
        window.location.href = currentAttr;
      }
    });
  })();
});