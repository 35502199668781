"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
document.addEventListener("DOMContentLoaded", function () {
  (function onSubnavigationContainerClick() {
    var Selectors = {
      ICON_WRAPPER: ".jsSubNavIconWrapper"
    };
    var activeClass = "active";
    var accordionSelector = "sub-nav-cntr-accordion";
    callForEachAccordionItem(function (accordionItem) {
      accordionItem.addEventListener("click", function () {
        this.classList.toggle(activeClass);
        toggleIcon(this);
        setPanelHeight(this);
      });
      openActiveAcordion(accordionItem);
    });
    window.addEventListener("resize", throttle(function () {
      return callForEachActiveAccordionItem(function (accordionItem) {
        setOpenPanelHeight(accordionItem);
      });
    }, 1000));
    function openActiveAcordion(accordionItem) {
      if (accordionItem.classList.contains(activeClass)) {
        toggleIcon(accordionItem);
        setPanelHeight(accordionItem);
      }
    }
    function toggleIcon(accordionItem) {
      var span = accordionItem.querySelector(Selectors.ICON_WRAPPER);
      if (!span) {
        return;
      }
      var img = span.children[0];
      if (!img) {
        return;
      }
      var plusIcon = img.classList.contains('dark') ? '/images/icons/circle-plus-dark.svg' : '/images/icons/circle-plus-light.svg';
      var minusIcon = img.classList.contains('dark') ? '/images/icons/circle-minus-dark.svg' : '/images/icons/circle-minus-light.svg';
      plusIcon = img.classList.contains('blue') ? '/images/icons/circle-plus-blue.svg' : plusIcon;
      minusIcon = img.classList.contains('blue') ? '/images/icons/circle-minus-blue.svg' : minusIcon;
      if (accordionItem.classList.contains(activeClass)) {
        img.src = minusIcon;
      } else {
        img.src = plusIcon;
      }
    }
    function isHidden(el) {
      return el.offsetParent === null;
    }
    function setOpenPanelHeight(accordionItem) {
      var panel = accordionItem.nextElementSibling;
      if (panel.style.maxHeight || isHidden(panel)) {
        return;
      }
      setPanelHeight(accordionItem);
    }
    function setPanelHeight(accordionItem) {
      var panel = accordionItem.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
    function callForEachActiveAccordionItem(callback) {
      callForEachAccordionItem(function (accordion) {
        if (accordion.classList.contains(activeClass)) {
          callback(accordion);
        }
        ;
      });
    }
    function callForEachAccordionItem(callback) {
      var accordions = document.getElementsByClassName(accordionSelector);
      if (!accordions) {
        return;
      }
      var _iterator = _createForOfIteratorHelper(accordions),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var accordionItem = _step.value;
          callback(accordionItem);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  })();
});
function throttle(func, ms) {
  var isThrottled = false,
    savedArgs,
    savedThis;
  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }
    func.apply(this, arguments);
    isThrottled = true;
    setTimeout(function () {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }
  return wrapper;
}