"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function addRecaptchaValidator() {
    if (!window.jQuery) {
      return;
    }
    if (typeof grecaptcha == "undefined") {
      return;
    }
    grecaptcha.ready(function () {
      if (epi == undefined || epi.EPiServer == undefined || epi.EPiServer.Forms == undefined) {
        return;
      }
      window.jQuery.extend(true, epi.EPiServer.Forms, {
        Validators: {
          "Intertek.CMS.Business.Validation.RecaptchaV2Validator": function IntertekCMSBusinessValidationRecaptchaV2Validator(fieldName, fieldValue, validatorMetaData) {
            return {
              isValid: grecaptcha.getResponse().length !== 0,
              message: validatorMetaData.model.message
            };
          }
        }
      });
    });
  })();
});