"use strict";

document.addEventListener("DOMContentLoaded", function () {
  (function dropdownExpand() {
    var nav = document.querySelector(".intertek-utility-nav");
    var dropdownMenu = document.querySelector(".worldwide-menu");
    if (nav) {
      nav.addEventListener("click", function (e) {
        if (e.target.closest(".intertek-utility-nav__link--dropdown")) {
          var dropdownLink = e.target.closest(".intertek-utility-nav__link--dropdown");
          e.preventDefault();
          dropdownLink.classList.toggle("active");
          if (dropdownMenu) {
            dropdownMenu.classList.toggle("active");
          }
        }
      });
    }
  })();
});