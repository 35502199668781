"use strict";

document.addEventListener("DOMContentLoaded", function () {
  var Selectors = {
    SPOTLIGHT_SWIPER: ".spotlight-swiper",
    BLOG_SWIPER: ".spotlight-blog-swiper"
  };
  if (typeof Swiper === "undefined") {
    return;
  }
  var spotlightSwipers = document.querySelectorAll(Selectors.SPOTLIGHT_SWIPER);
  var blogSpotlightSwipers = document.querySelectorAll(Selectors.BLOG_SWIPER);
  var initBlogSwiper = function initBlogSwiper(swiperBaseConfig) {
    var breakpointsConfig = {
      breakpoints: {
        320: {
          slidesPerView: 1.11,
          spaceBetween: 10
        },
        480: {
          slidesPerView: 1.4,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 1.6,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 1.8,
          spaceBetween: 16
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 15
        },
        1100: {
          slidesPerView: 3.2,
          spaceBetween: 15
        },
        1440: {
          slidesPerView: 4.68,
          spaceBetween: 30
        }
      }
    };
    var swiper = new Swiper(Selectors.BLOG_SWIPER, Object.assign(swiperBaseConfig, breakpointsConfig));
  };
  var initSwiper = function initSwiper(swiperBaseConfig) {
    var breakpointsConfig = {
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 10
        },
        480: {
          slidesPerView: 2.1,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 1.2,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 2.25,
          spaceBetween: 16
        },
        992: {
          slidesPerView: 2.5,
          spaceBetween: 15
        },
        1440: {
          slidesPerView: 3.53,
          spaceBetween: 30
        }
      }
    };
    var swiper = new Swiper(Selectors.SPOTLIGHT_SWIPER, Object.assign(swiperBaseConfig, breakpointsConfig));
  };
  var swiperBaseConfig = {
    navigation: {
      nextEl: ".swiper-navigation-arrow.swiper-navigation-arrow-right",
      prevEl: ".swiper-navigation-arrow.swiper-navigation-arrow-left"
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function renderBullet(index, className) {
        return '<span data-index="' + index + '" class="swiper-pagination-bullet ' + className + '" tabindex="0" role="button" aria-label="Go to slide ' + (index + 1) + '"></span>';
      }
    },
    slidesPerView: 1,
    spaceBetween: 10
  };
  if (spotlightSwipers && spotlightSwipers.length > 0) {
    initSwiper(swiperBaseConfig);
  }
  if (blogSpotlightSwipers && blogSpotlightSwipers.length > 0) {
    initBlogSwiper(swiperBaseConfig);
  }
});